<template>
    <div class="login row">
        <div class="col-5 login__left">
            <div class="login__left__form">
                <div class="logo__wrapper">
                    <q-icon size="32px" class="logo" name="svguse:/icons.svg#logo"/>
                    <div class="logo-subtitle">Tickets</div>
                </div>
                <h1 class="typography-h1 q-mt-none" style="margin-bottom: 32px;">Přihlášení</h1>
                <q-form class="column q-gutter-md" @submit.prevent="tryLogIn">
                    <div v-if="errorText" class="text-red text-caption">{{ errorText }}</div>
                    <q-input outlined class="my-input"
                             v-model="email"
                             type="email"
                             label="E-mail"
                             lazy-rules
                             :rules="[v => !!v || 'Zadejte e-mailovou adresu']"
                             autofocus
                    />
                    <q-input outlined
                             class="my-input"
                             v-model="password"
                             type="password"
                             label="Heslo"
                             lazy-rules
                             :rules="[v => !!v || 'Zadejte heslo']"
                             no-error-icon
                    />
                    <div class="row justify-center">
                        <q-btn class="my-btn" style="width: 330px" type="submit" color="black" label="Přihlásit se"/>
                    </div>
                </q-form>
            </div>
        </div>
        <div class="col-7">
            <img class="login__right-img" src="/images/adrianna-geo-1rBg5YSi00c-unsplash.jpg" alt="">
        </div>
    </div>
</template>

<script>
import Input from '../components/Input/Input/Input.vue'
import ButtonDefault from "../components/Button/ButtonDefault/ButtonDefault";

export default {
    components: {
        ButtonDefault,
        Input
    },
    data() {
        return {
            email: '',
            password: '',
            errorText: null,
        };
    },

    methods: {
        async tryLogIn() {
            try {
                await this.logIn();

                this.errorText = null;
            } catch (err) {
                if (err.response) {
                    if (err.response.status === 400) {
                        this.errorText = "Chybný e-mail nebo heslo";
                    } else if (err.response.status > 400) {
                        this.errorText = `Chyba ${err.response.status}`;
                    }
                } else {
                    console.error(err);

                    this.errorText = `Neznámá chyba`;
                }
            }
        },
    },

    mounted() {
        this.loggedIn = false;
    },

    meta() {
        return {
            title: 'Přihlášení do administrace',
        };
    },
};
</script>
<style lang="scss" scoped>
.login {
    height: 100vh;
    overflow: hidden;
    cursor: default;
}

.logo__wrapper {
    position: absolute;
    left: 0;
    top: 30px;
}

.login__right-img {
    height: 100vh;
    width: 100%;
    object-fit: cover;
}

.logo {
    width: 50px;
}

.logo-subtitle {
    font-family: 'MFGrotesk', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: -0.04em;
    color: $black-900;
}

.login__left {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 100vh;
}

.login__left__form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    height: 100vh;
    width: 80%;
    max-width: 550px;
}
</style>

